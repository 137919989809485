interface IServiceData {
  date: string;
  value: number;
}

export const formattedData = (
  serviceData: IServiceData[],
  step: number,
  label: string = "Ventas"
) => {
  const [labels, data, points] = correctInformation(serviceData);
  const labelFix = correctLabels(labels);
  const maxData = Math.max(...data);
  return {
    labels: [...labelFix],
    datasets: [
      {
        label: label,
        data: [...data, maxData + step],
        pointRadius: [...points],
        tension: 0.3,
        fill: true,
        backgroundColor: "rgba(181, 84, 204, 0.15)",
        pointBorderColor: "rgba(57, 43, 117, 1)",
        pointBackgroundColor: "rgba(255, 255, 255, 1)",
      },
    ],
    fill: {
      propagate: true,
    },
  };
};

const convertDate = (timestamp) => {
  const currentDate = new Date(timestamp);
  const year = currentDate.getFullYear().toString();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const splitDate = (dateString) => {
  const parts = dateString?.split("-");
  return new Date(parts[0], parts[1] - 1, parts[2]);
};

const correctInformation = (serviceData: IServiceData[]) => {
  serviceData = serviceData.reverse();
  const labels = [];
  const data = [];
  const points = [];

  // Today date formatted
  const today = new Date();
  const currentDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  // Period limit
  const numberOfDaysPassed = today.getDate();

  // Last index of service data (backend response)
  let lastIndex = 0;

  // Counter is the index of the number of days passed
  for (let counter = 0; counter < numberOfDaysPassed; counter++) {
    points.push(3);

    // If the index is out of bounds, add a 0 to the data array
    if (lastIndex > serviceData.length - 1) {
      labels.push(convertDate(currentDate));
      data.push(0);

      if (currentDate.getDay() === 1) {
        points.pop();
        points.push(6);
      }

      currentDate.setDate(currentDate.getDate() - 1);
    }

    if (lastIndex <= serviceData.length - 1) {
      // Service current date formatted
      const crudeDate = new Date(serviceData[lastIndex].date);
      const dataDate = new Date(
        crudeDate.getFullYear(),
        crudeDate.getMonth(),
        crudeDate.getDate() + 1
      );

      // Compare dates between service and current date
      if (compareDates(dataDate, currentDate)) {
        labels.push(serviceData[lastIndex].date);

        data.push(serviceData[lastIndex].value);
        currentDate.setDate(currentDate.getDate() - 1);

        if (dataDate.getDay() === 0) {
          points.pop();
          points.push(6);
        }
        lastIndex++;
      } else {
        labels.push(convertDate(currentDate));
        data.push(0);
        currentDate.setDate(currentDate.getDate() - 1);
      }
    }
  }

  return [[...labels.reverse()], [...data.reverse()], [...points.reverse()]];
};

const weekdays = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];

const correctLabels = (labels) => {
  return labels.map((l) => {
    const date = splitDate(l);
    return `${weekdays[date.getDay()]} ${date.getDate()}`;
  });
};

const compareDates = (date1, date2) => {
  const day1 = date1.getDate();
  const month1 = date1.getMonth();
  const year1 = date1.getFullYear();
  const day2 = date2.getDate();
  const month2 = date2.getMonth();
  const year2 = date2.getFullYear();

  return day1 === day2 && month1 === month2 && year1 === year2;
};
