import React, { useEffect, useRef, useState } from "react";
import style from "./CardsWithIcon.module.css";

// Functions
import { formatToSoles } from "@Utilities/texts";
// @ts-ignore
import { Icon } from "@viuti/recursos";

const CardsWithIcon = ({
  label,
  data,
  isMoneyFormat = false,
  icon = null,
  iconColor = "",
  altIcon = "",
  sizeIcon = 22,
}: {
  label: string;
  data: { currentMonth: number; previousMonth: number };
  isMoneyFormat?: boolean;
  icon?: any;
  iconColor?: string;
  altIcon?: string;
  sizeIcon?: number;
}) => {
  return (
    <div className={`${style.cardContainer}`}>
      <Icon path={icon} size={sizeIcon} color={iconColor} alt={altIcon} />
      <span className={style.totalSalesContainer}>
        <p className={`${style.totalSalesMessage} ${style.totalSalesMessage}`}>
          {label}
        </p>
        <strong className={style.totalSalesValue}>
          {isMoneyFormat ? formatToSoles(data.currentMonth) : data.currentMonth}
        </strong>
      </span>
    </div>
  );
};

export default CardsWithIcon;
