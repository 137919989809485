// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EU6Mw8YTTkCqXwTlKMIz {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.HpJSYfIth6oKTwBvACOe {
  color: #45348e;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
}

.gdvBq0ogKTdxHkdSs2QY {
  height: 100%;
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #d3d3d3;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/CardTemplate/CardTemplate.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".cardContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.cardTitle {\n  color: #45348e;\n  font-family: Mulish;\n  font-size: 18px;\n  font-weight: 700;\n}\n\n.cardContent {\n  height: 100%;\n  display: flex;\n  padding: 20px 24px;\n  flex-direction: column;\n  align-items: flex-start;\n  background-color: #fff;\n  border-radius: 8px;\n  border: 1px solid #d3d3d3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `EU6Mw8YTTkCqXwTlKMIz`,
	"cardTitle": `HpJSYfIth6oKTwBvACOe`,
	"cardContent": `gdvBq0ogKTdxHkdSs2QY`
};
export default ___CSS_LOADER_EXPORT___;
