import React from "react";
import style from "./TodayModule.module.css";
import SectionTitle from "@Components/ui/atoms/SectionTitle/SectionTitle";
import CardTemplate from "@Components/ui/atoms/CardTemplate/CardTemplate";
import CardWithGraph from "@Components/ui/atoms/CardWithGraph/CardWithGraph";
import CardSalesMade from "@Components/ui/atoms/CardSalesMade/CardSalesMade";
import CardActiveClients from "@Components/ui/atoms/CardActiveClients/CardActiveClients";
import { useAppSelector } from "@Hooks/store";

const TodayModule = () => {
  const salesState = useAppSelector((state) => state.sales);
  return (
    <>
      <SectionTitle idPeriod={1} />
      <section className={style.sectionCardsContainer}>
        <CardTemplate title="Ventas" flex={1}>
          <CardWithGraph
            salesData={salesState.dailyData.sales}
            comparisonPeriodText={"ayer"}
          />
        </CardTemplate>
        <div className={style.smallCardsContainer}>
          <div className={style.cardTemplateContainer}>
            <CardTemplate
              title="Ventas realizadas"
              componentCustomStyle={{ height: "100%" }}
            >
              <CardSalesMade
                totalSales={salesState.dailyData.sales.todaySales}
                averageTicket={salesState.dailyData.sales.averageTickets}
                hasBetterSales={
                  Number(salesState.dailyData.sales.totalSales.salesQuantity) >
                  Number(
                    salesState.dailyData.sales.yestardaySales.salesQuantity
                  )
                }
                comparativeSales={
                  salesState.dailyData.sales.totalSales.salesQuantity -
                  salesState.dailyData.sales.yestardaySales.salesQuantity
                }
                formatComparativeSales={false}
                comparisonPeriodText={"ayer"}
              />
            </CardTemplate>
          </div>

          {/* <div className={style.cardTemplateContainer}>
            <CardTemplate
              title="Clientes activos"
              componentCustomStyle={{ height: "100%" }}
            >
              <CardActiveClients {...salesState.dailyData} />
            </CardTemplate>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default TodayModule;
