import { configureStore } from "@reduxjs/toolkit";

// Data reducers
import salesReducer, { ISalesState } from "./states/salesSlice";

// Components reducers
import notificationReducer, {
  INotificationState,
} from "./states/notificationSlice";

export const store = configureStore({
  reducer: {
    sales: salesReducer,
    notificationConfig: notificationReducer,
  },
});

export type RootState = {
  sales: ISalesState; // Cambié el tipo aquí
  notificationConfig: INotificationState;
};

//export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppSelector = (state: RootState) => RootState;
