import { useEffect, useState } from "react";
import { ROUTES } from "@Models/routes";
import { Route, Routes } from "react-router-dom";
import Index from "@Pages/index";
import style from "./context.module.css";
import {
  CriticalErrorPage,
  NotFoundPage,
  SlideNotification,
} from "@Models/resources-components";
import { useAppDispatch, useAppSelector } from "@Hooks/store";
import { clearNotification } from "@ReduxService/states/notificationSlice";
import { getSales } from "@Adapters/getSales.adapter";
import { LoadingPulseIcon } from "@Models/icons";
import { STATUS_OK } from "@Models/httpsStatus";

const ContextRoutes = () => {
  const dispatch = useAppDispatch();

  //? State for api calls handling
  const [promiseStatus, setPromiseStatus] = useState({
    sales: { status: 0, message: "" },
  });

  useEffect(() => {
    // Fetch employees data
    getSales(dispatch).then((response) => {
      setPromiseStatus({
        ...promiseStatus,
        sales: { status: response.status, message: response.message },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Slide Notification
  const notificationState = useAppSelector((state) => state.notificationConfig);

  return (
    <div id="viuti-front-mainContent">
      {promiseStatus.sales.status === 0 ? (
        <article className={style.loadingContainer}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </article>
      ) : (
        <>
          {/* @ts-ignore */}
          <Routes>
            {/* @ts-ignore */}
            <Route
              path={ROUTES.HOME}
              element={
                promiseStatus.sales.status !== STATUS_OK ? (
                  <CriticalErrorPage />
                ) : (
                  <Index />
                )
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </>
      )}

      <SlideNotification
        state={notificationState}
        clearStatus={() => dispatch(clearNotification())}
      />
    </div>
  );
};

export default ContextRoutes;
