import React, { useEffect, useState } from "react";
// @ts-ignore
import style from "./GoalCard.module.css";

// Components
import UnsetGoal from "./components/UnsetGoal";
import SettedGoal from "./components/SettedGoal";
import CompletedGoal from "./components/CompletedGoal";

const GoalCard = ({ title, flex, data, periodType, isMonth }) => {
  const [goalValue, setGoalValue] = useState(String(data.goal.value) || "0");
  const [inputGoalValue, setInputGoalValue] = useState(data.goal || "0");
  const [isInputVisible, setIsInputVisible] = useState(false);

  useEffect(() => {
    setGoalValue(String(data.goal.value));
    setInputGoalValue(data.goal.value);
  }, [data.goal.value]);

  const [isGoalAchieved, setIsGoalAchieved] = useState(false);

  useEffect(() => {
    const parseNumber = (value: any): number => {
      if (typeof value === "number") return value;
      if (typeof value === "string") return parseFloat(value.replace(/,/g, ""));
      return 0;
    };

    const currentSales = parseNumber(data.sales.totalSales.sales);
    const goal = parseNumber(goalValue);

    setIsGoalAchieved(currentSales >= goal);
  }, [data.sales.totalSales.sales, goalValue]);

  return (
    <article className={style.cardContainer} style={{ flex: flex }}>
      <strong className={style.cardTitle}>{title}</strong>
      {goalValue === "0" || isInputVisible ? (
        <UnsetGoal
          periodType={periodType}
          idGoal={data.goal.idGoal}
          goalValue={goalValue}
          setGoalValue={setGoalValue}
          inputGoalValue={inputGoalValue}
          setInputGoalValue={setInputGoalValue}
          isInputVisible={isInputVisible}
          setIsInputVisible={setIsInputVisible}
          isMonth={isMonth}
        />
      ) : !isGoalAchieved ? (
        <SettedGoal
          salesValue={data.sales.totalSales.sales}
          goalValue={goalValue}
          setIsInputVisible={setIsInputVisible}
          isMonth={isMonth}
        />
      ) : (
        <CompletedGoal
          salesValue={data.sales.totalSales.sales}
          goalValue={goalValue}
          setIsInputVisible={setIsInputVisible}
          isMonth={isMonth}
        />
      )}
    </article>
  );
};

export default GoalCard;
