function formattedMoney(number, applyFormat = true) {
  // Convertir el valor a número
  let numericValue = parseFloat(number);

  // Verificar si el número es válido
  // if (isNaN(numericValue)) {
  //   throw new TypeError('El parámetro debe ser un número o un string de números');
  // }

  // Formatear el número si se especifica que se debe aplicar el formato
  if (applyFormat) {
    numericValue = Number(numericValue);
    const formattedNumber = numericValue.toLocaleString("es-PE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  } else {
    return numericValue.toFixed(2);
  }
}

const options = (title, steps, label = "", formatted = false) => {
  return {
    responsive: true,
    elements: {
      point: {
        borderWidth: 2,
        pointRadius: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(147, 124, 244, 1)",
        pointHoverBorderWidth: 2,
      },
      line: {
        borderWidth: 0,
      },
      tooltip: {
        shadowOffsetX: 5,
        shadowOffsetY: 5,
        shadowBlur: 10,
        shadowColor: "rgba(0, 0, 0, 1)",
        padding: 10,
      },
    },
    scales: {
      y: {
        min: 0,
        grid: {
          color: "rgba(147, 124, 244, 0.3)",
        },
        ticks: {
          stepSize: steps,
          padding: 13,
          font: {
            size: 11,
            family: "sans-serif",
            weight: 700,
          },
        },
        border: {
          display: false,
        },
      },
      x: {
        // padding: 100,
        grid: {
          display: false,
        },
        ticks: {
          padding: 8,
          font: {
            size: 11,
            family: "sans-serif",
            weight: 700,
          },
        },
      },
    },
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      tooltip: {
        displayColors: false,
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderWidth: 1,
        borderColor: "rgba(217, 217, 217, 1)",
        bodyColor: "rgba(69, 52, 142, 1)",
        bodyAlign: "center",
        titleColor: "rgba(69, 52, 142, 1)",
        titleSpacing: 1,
        titleFont: {
          size: 12,
          weight: 600,
          family: "sans-serif",
        },
        bodyFont: {
          size: 12,
          weight: 600,
          family: "sans-serif",
        },
        titleAlign: "center",
        padding: {
          x: 12,
          y: 4,
        },
        yAlign: "bottom",
        callbacks: {
          title: () => {
            return `${title}:`;
          },
          label: (context) => {
            return formatted
              ? `${label} ${formattedMoney(context.parsed.y)}`
              : `${label} ${context.parsed.y}`;
          },
          //${label}${formattedMoney(context.parsed.y)
        },
      },
    },
    layout: {
      padding: 20,
    },
  };
};

export default options;
