// Get current date with format dd/mm/yyyy
export const getCurrentDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

function formatDateInverse(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const getTodayAndYesterday = () => {
  const date = new Date();

  // Formatear la fecha de hoy
  const todayDay = String(date.getDate()).padStart(2, "0");
  const todayMonth = String(date.getMonth() + 1).padStart(2, "0");
  const todayYear = date.getFullYear();

  const today = `${todayYear}-${todayMonth}-${todayDay}`;

  // Ajustar la fecha para el día anterior
  date.setDate(date.getDate() - 1);

  // Formatear la fecha de ayer
  const yesterdayDay = String(date.getDate()).padStart(2, "0");
  const yesterdayMonth = String(date.getMonth() + 1).padStart(2, "0");
  const yesterdayYear = date.getFullYear();

  const yesterday = `${yesterdayYear}-${yesterdayMonth}-${yesterdayDay}`;

  return {
    today: today,
    yesterday: yesterday,
  };
};

export const getMondayAndSundayOfCurrentWeek = () => {
  const currentDate = new Date();

  // Obtenemos el día de la semana (0 = domingo, 1 = lunes, ..., 6 = sábado)
  const currentDay = currentDate.getDay();

  // Calculamos la diferencia para llegar al lunes (1) y al domingo (0)
  const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
  const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;

  // Creamos nuevos objetos de fecha para el lunes y el domingo
  const monday = new Date(currentDate);
  monday.setDate(currentDate.getDate() + diffToMonday);

  const sunday = new Date(currentDate);
  sunday.setDate(currentDate.getDate() + diffToSunday);

  // Función para formatear la fecha en YYYY-MM-DD

  // Devolvemos las fechas del lunes y el domingo
  return {
    monday: formatDateInverse(monday),
    sunday: formatDateInverse(sunday),
  };
};

export const getMondayAndSundayOfLastWeek = () => {
  const currentDate = new Date();

  // Obtenemos el día de la semana (0 = domingo, 1 = lunes, ..., 6 = sábado)
  const currentDay = currentDate.getDay();

  // Calculamos la diferencia para llegar al lunes y al domingo de la semana pasada
  const diffToLastMonday = currentDay === 0 ? -13 : -6 - currentDay;
  const diffToLastSunday = currentDay === 0 ? -7 : -currentDay;

  // Creamos nuevos objetos de fecha para el lunes y el domingo de la semana pasada
  const lastMonday = new Date(currentDate);
  lastMonday.setDate(currentDate.getDate() + diffToLastMonday);

  const lastSunday = new Date(currentDate);
  lastSunday.setDate(currentDate.getDate() + diffToLastSunday);

  // Devolvemos las fechas del lunes y el domingo de la semana pasada
  return {
    monday: formatDateInverse(lastMonday),
    sunday: formatDateInverse(lastSunday),
  };
};
