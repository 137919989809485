import React from "react";
import style from "../styles/CompletedGoal.module.css";
// @ts-ignore
import { ButtonWithoutIcon, Icon, validateModuleAccess } from "@viuti/recursos";
import { ArrowLeftIcon, TrophyIcon } from "@Models/icons";
import { formatToSoles } from "@Utilities/texts";
import { UPSERT_GOALS_UUID } from "@Models/const/securityAccess";

const CompletedGoal = ({
  salesValue,
  goalValue,
  setIsInputVisible,
  isMonth,
}) => {
  const handleGoalSubmit = (e) => {
    setIsInputVisible(true);
  };
  return (
    <section className={style.cardContent}>
      <div className={style.bluredCircles}></div>
      <div className={style.bluredCircles}></div>
      <div className={style.bluredCircles}></div>
      <div className={style.iconContainer}>
        <Icon path={TrophyIcon} color={"#FFC700"} size={56} />
      </div>
      <div className={style.goalContent}>
        <div className={style.goalsContainer}>
          <h2 className={style.goalMeesageContainer}>¡Meta lograda!</h2>
          <span className={style.goalMeesageContainer}>
            <p className={style.goalMessage}>
              {isMonth ? "Meta del mes:" : "Meta de la semana:"}{" "}
            </p>
            <p className={style.goalValue}>
              {formatToSoles(Number(goalValue))}
            </p>
          </span>
          <div className={style.performanceValuesContainer}>
            <span className={style.goalMeesageContainer}>
              <p className={style.goalMessage}>Has vendido: </p>
              <p className={style.goalValue}>{formatToSoles(salesValue)}</p>
            </span>
            <span className={style.performanceContainer}>
              <div className={style.performanceIcon}>
                <Icon path={ArrowLeftIcon} color={"#00B71D"} size={12}></Icon>
              </div>
              <p className={style.performanceValue}>
                {((salesValue / goalValue) * 100 - 100).toFixed(2)}% más
              </p>
            </span>
          </div>
        </div>
        <ButtonWithoutIcon
          isPrimary={true}
          textBttn="Editar meta"
          handleClick={(e) =>
            validateModuleAccess(UPSERT_GOALS_UUID) && handleGoalSubmit(e)
          }
          isDisabled={false}
          width={"fit-content"}
        />
      </div>
    </section>
  );
};

export default CompletedGoal;
