// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J5yjxoq8IlECJWrfXp1p {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.DawT_k0IF6vy_0UiHPOP {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  flex-wrap: wrap;
}

.amNBoTUgLFL5_7HBOptE {
  /* width: 100%; */
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.sI9fziy0tjdd0rLhrQjN {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 552px) {
  .amNBoTUgLFL5_7HBOptE {
    flex-direction: column;
  }
  .sI9fziy0tjdd0rLhrQjN {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/modules/TodayModule/TodayModule.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".module__container {\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n}\n\n.sectionCardsContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: stretch;\n  gap: 20px;\n  flex-wrap: wrap;\n}\n\n.smallCardsContainer {\n  /* width: 100%; */\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  gap: 20px;\n}\n\n.cardTemplateContainer {\n  width: 100%;\n  height: 100%;\n}\n\n@media screen and (max-width: 552px) {\n  .smallCardsContainer {\n    flex-direction: column;\n  }\n  .cardTemplateContainer {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"module__container": `J5yjxoq8IlECJWrfXp1p`,
	"sectionCardsContainer": `DawT_k0IF6vy_0UiHPOP`,
	"smallCardsContainer": `amNBoTUgLFL5_7HBOptE`,
	"cardTemplateContainer": `sI9fziy0tjdd0rLhrQjN`
};
export default ___CSS_LOADER_EXPORT___;
