import { IMonthlyData } from "@Models/interfaces/sales";

export const MOCK_MONTHLY_DATA: IMonthlyData = {
  sales: {
    totalSales: {
      sales: 0,
      salesQuantity: 0,
    },
    yestardaySales: {
      sales: 0,
      salesQuantity: 0,
    },
    monthlySalesChart: [],
    purchases: {
      giftcards: {
        currentMonth: {
          total: 0,
          totalAmount: 0,
        },
        previousMonth: {
          total: 0,
          totalAmount: 0,
        },
      },
      products: [],
      packages: [],
      services: [],
    },
    sales: {
      averageTickets: {
        currentMonth: 0,
        previousMonth: 0,
      },
      totalSales: {
        currentMonth: 0,
        previousMonth: 0,
      },
      clientsAttended: {
        currentMonth: 0,
        previousMonth: 0,
      },
      appointments: {
        currentMonth: 0,
        previousMonth: 0,
      },
    },
  },
  goal: {
    idGoal: 0,
    value: 0,
  },
};
