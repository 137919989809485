// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ROTMcHDX6rX_oNiWhxkP {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 30px;
  flex-wrap: wrap;
}

.OfqIXQAKs0289NmZKuIo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 30px;
  flex-wrap: wrap;
}

.CfMJFo2lVJSqZq2wUKwt {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  gap: 20px;
  justify-content: space-between;
}

.V9MdvCyvxTeUzQvqizIl {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.wpQaYGFo3dfWC9rhBo1g {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.U3TiA0MTZL38w55K63A0 {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 3;
  gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/modules/MonthModule/MonthModule.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,OAAO;EACP,SAAS;EACT,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,OAAO;EACP,SAAS;AACX","sourcesContent":[".sectionCardsContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: stretch;\n  gap: 30px;\n  flex-wrap: wrap;\n}\n\n.firstRowContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: stretch;\n  gap: 30px;\n  flex-wrap: wrap;\n}\n\n.salesCardsColumnContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  flex: 1;\n  gap: 20px;\n  justify-content: space-between;\n}\n\n.monthSalesGraphContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n}\n\n.cardTemplateLabel {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 18px;\n  font-weight: 700;\n}\n\n.purchasesSalesCardsContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  flex: 3;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionCardsContainer": `ROTMcHDX6rX_oNiWhxkP`,
	"firstRowContainer": `OfqIXQAKs0289NmZKuIo`,
	"salesCardsColumnContainer": `CfMJFo2lVJSqZq2wUKwt`,
	"monthSalesGraphContainer": `V9MdvCyvxTeUzQvqizIl`,
	"cardTemplateLabel": `wpQaYGFo3dfWC9rhBo1g`,
	"purchasesSalesCardsContainer": `U3TiA0MTZL38w55K63A0`
};
export default ___CSS_LOADER_EXPORT___;
