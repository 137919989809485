import LineChart from "../GraphsBuilder/LineGraph/LineGraph";
import { formattedData } from "./builder/data";
import options from "./builder/options";
import { useState } from "react";

// Styles
// @ts-ignore
import style from "./MonthGraph.module.css";
import BooleanButtons from "../BooleanButtons/BooleanButtons";
import { MONEY_MEASUREMENT } from "@Models/const/measurements";

const MonthGraph = ({ arrayData }) => {
  const [selected, setSelected] = useState(MONEY_MEASUREMENT);

  const arrDinero = arrayData.map((arr) => {
    return {
      value: arr.total,
      date: arr.date,
    };
  });

  const arrCantidad = arrayData.map((arr) => {
    return {
      value: arr.sales,
      date: arr.date,
    };
  });

  return (
    <section className={style.graphContainer}>
      <article className={style.graphHeader}>
        <BooleanButtons value={selected} onChange={setSelected} />
      </article>

      <article className={style.graph}>
        {selected === MONEY_MEASUREMENT ? (
          <LineChart
            data={formattedData(arrDinero, 50)}
            options={options("Ventas", 1, "S/ ", true)}
          />
        ) : (
          <LineChart
            data={formattedData(arrCantidad, 10)}
            options={options("Cantidad", 1)}
          />
        )}
      </article>
    </section>
  );
};

export default MonthGraph;
