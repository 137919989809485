// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opx9NGkNT6Z_UlwfY33z {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/CardWithGraph/CardWithGraph.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC","sourcesContent":[".graphContainer {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"graphContainer": `opx9NGkNT6Z_UlwfY33z`
};
export default ___CSS_LOADER_EXPORT___;
