// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YauotwTG4Pp5juGfArvL {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/routes/context.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".loadingContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": `YauotwTG4Pp5juGfArvL`
};
export default ___CSS_LOADER_EXPORT___;
