import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import style from "./CardTemplate.module.css";

interface ICardTemplate {
  children: React.ReactNode;
  title?: string;
  flex?: number;
  componentCustomStyle?: React.CSSProperties;
  containerCustomStyle?: React.CSSProperties;
}

const CardTemplate = ({
  children,
  title,
  flex,
  componentCustomStyle = {},
  containerCustomStyle = {},
}: ICardTemplate) => {
  return (
    <article
      className={`${style.cardContainer} `}
      style={{ flex: flex, ...componentCustomStyle }}
    >
      {title && <strong className={style.cardTitle}>{title}</strong>}
      <section
        className={style.cardContent}
        style={{ ...containerCustomStyle }}
      >
        {children}
      </section>
    </article>
  );
};

export default CardTemplate;
