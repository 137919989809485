import ContextRoutes from "@Routes/Context.route";
import { BrowserRouter as Router } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import { store } from "@ReduxService/index";

export default function app() {
  return (
    <Provider store={store}>
      <Router>
        <ContextRoutes />
      </Router>
    </Provider>
  );
}
