import { AxiosResponse } from "axios";
import { STATUS_OK } from "@Models/httpsStatus";

const tokenLocal = localStorage.getItem("access_token");
export const baseUrl = `https://gw-qa.zymple.io/salesStatistics/v1`;

export const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenLocal}`,
  },
};

export const responseHandler = (getResponse: () => AxiosResponse<any>) => {
  try {
    const localResponse = getResponse().data;

    if (localResponse.status === STATUS_OK) {
      return {
        status: localResponse.status,
        message: localResponse.message,
        data: localResponse.data,
      };
    } else {
      return {
        status: localResponse.status,
        message: localResponse.data.message,
        data: null,
      };
    }
  } catch (error) {
    return {
      status: 400,
      message:
        error.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
      data: null,
    };
  }
};
