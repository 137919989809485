// Models
import { STATUS_INTERNAL_SERVER_ERROR, STATUS_OK } from "@Models/httpsStatus";
import {
  IDailyData,
  IMonthlyData,
  IWeeklyData,
} from "@Models/interfaces/sales";

// Services
import { getDailySales_service } from "@Services/getSales/getDailySales";
import { getMonthlySales_service } from "@Services/getSales/getMonthlySales";
import { getWeeklySales_service } from "@Services/getSales/getWeeklySales";

// Redux
import { AppDispatch } from "@ReduxService/index";
import {
  updateCustomPeriodData,
  updateDailySalesData,
  updateMonthlySalesData,
  updateWeeklySalesData,
} from "@ReduxService/states/salesSlice";
import {
  getMondayAndSundayOfCurrentWeek,
  getMondayAndSundayOfLastWeek,
  getTodayAndYesterday,
} from "@Utilities/dateFunctions";

export const getSales = async (dispatch: AppDispatch) => {
  const currentDays = getTodayAndYesterday();
  const today = currentDays.today;
  const yesterday = currentDays.yesterday;

  const currentWeek = getMondayAndSundayOfCurrentWeek();
  const startOfWeek = currentWeek.monday;
  const endOfWeek = currentWeek.sunday;

  const lastWeek = getMondayAndSundayOfLastWeek();
  const startOfLastWeek = lastWeek.monday;
  const endOfLastWeek = lastWeek.sunday;

  const startOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  )
    .toISOString()
    .split("T")[0];
  const endOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  )
    .toISOString()
    .split("T")[0];

  try {
    const dailySalesResponse = await getDailySales_service(today);
    const yesterdaySalesResponse = await getDailySales_service(yesterday);
    const weeklySalesResponse = await getWeeklySales_service(
      startOfWeek,
      endOfWeek
    );
    const lastWeekSalesResponse = await getWeeklySales_service(
      startOfLastWeek,
      endOfLastWeek
    );
    const monthlySalesResponse = await getMonthlySales_service(
      startOfMonth,
      endOfMonth
    );

    let response = {
      dailySales: dailySalesResponse.data,
      yesterdaySales: yesterdaySalesResponse.data,
      weeklySales: weeklySalesResponse.data,
      lastWeekSales: lastWeekSalesResponse.data,
      monthlySales: monthlySalesResponse.data,
    };

    const dailyCustomers = response.dailySales.customerDetails.map(
      (customer) => {
        return {
          id: customer.customerId,
          name: customer.customerName,
        };
      }
    );

    const dailySales: IDailyData = {
      totalActiveCustomers: response.dailySales.totalActiveCustomers,
      sales: {
        todaySales: response.dailySales.dailySales[0].sales || 0,
        averageTickets: response.dailySales.dailySales[0].averageTicket,
        totalSales: {
          sales: response.dailySales.dailySales[0].total || 0,
          salesQuantity:
            response.dailySales.dailySales[0].totalProductQuantity || 0,
          customers:
            response.dailySales.dailySales[0].totalCustomersServed || 0,
        },
        yestardaySales: {
          sales: response.yesterdaySales.dailySales[0].total || 0,
          salesQuantity:
            response.yesterdaySales.dailySales[0].totalProductQuantity || 0,
          customers:
            response.dailySales.dailySales[0].totalCustomersServed || 0,
        },
        giftcards: {
          total: response.dailySales.dailySales[0].totalGiftCard || 0,
          totalAmount: response.dailySales.dailySales[0].giftCardQuantity || 0,
        },
        yestardayGiftcards: {
          total: response.yesterdaySales.dailySales[0].totalGiftCard,
          totalAmount: response.yesterdaySales.dailySales[0].giftCardQuantity,
        },
        products: {
          total: response.dailySales.dailySales[0].totalProducts || 0,
          totalAmount: response.dailySales.dailySales[0].productQuantity || 0,
        },
        yestardayProducts: {
          total: response.yesterdaySales.dailySales[0].totalProducts,
          totalAmount: response.yesterdaySales.dailySales[0].productQuantity,
        },
        packages: {
          total: response.dailySales.dailySales[0].totalPackage || 0,
          totalAmount: response.dailySales.dailySales[0].packageQuantity || 0,
        },
        yestardayPackages: {
          total: response.yesterdaySales.dailySales[0].totalPackage || 0,
          totalAmount:
            response.yesterdaySales.dailySales[0].packageQuantity || 0,
        },
        services: {
          total: response.dailySales.dailySales[0].totalServices || 0,
          totalAmount: response.dailySales.dailySales[0].serviceQuantity || 0,
        },
        yestardayServices: {
          total: response.yesterdaySales.dailySales[0].totalServices,
          totalAmount: response.yesterdaySales.dailySales[0].serviceQuantity,
        },
      },
      customerDetails: dailyCustomers,
    };

    const weeklySales: IWeeklyData = {
      sales: {
        todaySales: response.dailySales.dailySales[0].total || 0,
        weeklySales: response.weeklySales.information[0].sales || 0,
        averageTickets: response.weeklySales.information[0].averageTicket,
        totalSales: {
          sales: response.weeklySales.information[0].total || 0,
          salesQuantity:
            response.weeklySales.information[0].totalProductQuantity || 0,
          customers:
            response.weeklySales.information[0].totalCustomersServed || 0,
        },
        yestardaySales: {
          sales: response.lastWeekSales.information[0].total || 0,
          salesQuantity:
            response.lastWeekSales.information[0].totalProductQuantity || 0,
          customers:
            response.lastWeekSales.information[0].totalCustomersServed || 0,
        },
        giftcards: {
          total: response.weeklySales.information[0].totalGiftCard || 0,
          totalAmount:
            response.weeklySales.information[0].giftCardQuantity || 0,
        },
        yestardayGiftcards: {
          total: response.lastWeekSales.information[0].totalGiftCard || 0,
          totalAmount:
            response.lastWeekSales.information[0].giftCardQuantity || 0,
        },
        products: {
          total: response.weeklySales.information[0].totalProducts || 0,
          totalAmount: response.weeklySales.information[0].productQuantity || 0,
        },
        yestardayProducts: {
          total: response.lastWeekSales.information[0].totalProducts || 0,
          totalAmount:
            response.lastWeekSales.information[0].productQuantity || 0,
        },
        packages: {
          total: response.weeklySales.information[0].totalPackage || 0,
          totalAmount: response.weeklySales.information[0].packageQuantity || 0,
        },
        yestardayPackages: {
          total: response.lastWeekSales.information[0].totalPackage || 0,
          totalAmount:
            response.lastWeekSales.information[0].packageQuantity || 0,
        },
        services: {
          total: response.weeklySales.information[0].totalServices || 0,
          totalAmount: response.weeklySales.information[0].serviceQuantity || 0,
        },
        yestardayServices: {
          total: response.lastWeekSales.information[0].totalServices || 0,
          totalAmount:
            response.lastWeekSales.information[0].serviceQuantity || 0,
        },
      },
      goal: {
        idGoal: response.weeklySales.goal.id,
        value: response.weeklySales.goal.monthlyGoal,
      },
    };

    const monthlySales: IMonthlyData = {
      sales: {
        totalSales: {
          sales:
            response.monthlySales.monthlySalesData.currentMonthSales
              .monthlySales || 0,
          salesQuantity:
            response.monthlySales.monthlySalesData.currentMonthSales
              .totalProductQuantity || 0,
        },
        yestardaySales: {
          sales: 0,
          salesQuantity: 0,
        },
        monthlySalesChart: response.monthlySales.monthlySalesChart,
        purchases: {
          giftcards: {
            currentMonth: {
              total:
                response.monthlySales.giftcardData.currentMonthGiftcards[0]
                  ?.quantity || 0,
              totalAmount:
                response.monthlySales.giftcardData.currentMonthGiftcards[0]
                  ?.total || 0,
            },
            previousMonth: {
              total:
                response.monthlySales.giftcardData.previousMonthGiftcards[0]
                  ?.quantity || 0,
              totalAmount:
                response.monthlySales.giftcardData.previousMonthGiftcards[0]
                  ?.total || 0,
            },
          },
          products: response.monthlySales.monthlyServiceData.products,
          packages: response.monthlySales.monthlyServiceData.packages,
          services: response.monthlySales.monthlyServiceData.services,
        },
        sales: {
          averageTickets: {
            currentMonth:
              response.monthlySales.monthlySalesData.currentMonthSales
                .averageTicket,
            previousMonth:
              response.monthlySales.monthlySalesData.previousMonthSales
                .averageTicket,
          },
          totalSales: {
            currentMonth:
              response.monthlySales.monthlySalesData.currentMonthSales
                .monthlySales,
            previousMonth:
              response.monthlySales.monthlySalesData.previousMonthSales
                .monthlySales,
          },
          clientsAttended: {
            currentMonth:
              response.monthlySales.monthlySalesData.currentMonthSales
                .customersServed,
            previousMonth:
              response.monthlySales.monthlySalesData.previousMonthSales
                .customersServed,
          },
          appointments: {
            currentMonth:
              response.monthlySales.monthlySalesData.currentMonthSales
                .reservationCount,
            previousMonth:
              response.monthlySales.monthlySalesData.previousMonthSales
                .reservationCount,
          },
        },
      },
      goal: {
        idGoal: response.monthlySales.goalsData.id,
        value: response.monthlySales.goalsData.monthlyGoal,
      },
    };

    dispatch(updateDailySalesData(dailySales));
    dispatch(updateMonthlySalesData(monthlySales));
    dispatch(updateCustomPeriodData(monthlySales));
    dispatch(updateWeeklySalesData(weeklySales));

    let finalStatus = STATUS_OK;
    if (
      dailySalesResponse.status !== STATUS_OK ||
      yesterdaySalesResponse.status !== STATUS_OK ||
      weeklySalesResponse.status !== STATUS_OK ||
      lastWeekSalesResponse.status !== STATUS_OK ||
      monthlySalesResponse.status !== STATUS_OK
    ) {
      finalStatus = STATUS_INTERNAL_SERVER_ERROR;
    }

    return {
      status: finalStatus,
      message: "Operación realizada con éxito",
    };
  } catch (error) {
    return {
      status: STATUS_INTERNAL_SERVER_ERROR,
      message: "Error al obtener las ventas,\n intente de nuevo más tarde",
    };
  }
};
