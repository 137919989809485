import React, { useState } from "react";

// Styles
// @ts-ignore
import templateStyle from "../CardTemplate/CardContent.module.css";
// @ts-ignore
import style from "./CardWithGraph.module.css";

// Components
import BooleanButtons from "@Components/ui/atoms/BooleanButtons/BooleanButtons";
import SalesBarGraph from "@Components/ui/atoms/SalesBarGraph/SalesBarGraph";

// Models
import { QUANTITY_MEASUREMENT } from "@Models/const/measurements";
import { formatToSoles } from "@Utilities/texts";
import { CommonArrowRightIcon } from "@Models/icons";
import { IDailySales } from "@Models/interfaces/sales";

const CardWithGraph = ({
  salesData,
  comparisonPeriodText,
}: {
  salesData: IDailySales;
  comparisonPeriodText: string;
}) => {
  const [salesCardMeasurement, setSetsalesCardMeasurement] =
    useState(QUANTITY_MEASUREMENT);

  const graphData = {
    giftcards:
      salesCardMeasurement === QUANTITY_MEASUREMENT
        ? salesData.giftcards.totalAmount
        : salesData.giftcards.total,
    products:
      salesCardMeasurement === QUANTITY_MEASUREMENT
        ? salesData.products.totalAmount
        : salesData.products.total,
    packages:
      salesCardMeasurement === QUANTITY_MEASUREMENT
        ? salesData.packages.totalAmount
        : salesData.packages.total,
    services:
      salesCardMeasurement === QUANTITY_MEASUREMENT
        ? salesData.services.totalAmount
        : salesData.services.total,
  };

  const hasBetterSales =
    Number(salesData.totalSales.sales) > Number(salesData.yestardaySales.sales);

  return (
    <div className={templateStyle.cardContainer}>
      <div className={templateStyle.cardValuesContent}>
        <strong className={templateStyle.totalSalesValue}>
          {formatToSoles(salesData.totalSales.sales)}
        </strong>
        <p className={templateStyle.totalSalesMessage}>
          En ventas hasta el momento
        </p>

        <span className={templateStyle.yestardaySalesContainer}>
          {salesData.totalSales.sales - salesData.yestardaySales.sales === 0 ? (
            <p className={`${templateStyle.yesterdaySalesMessage} `}>
              No hubo cambios
            </p>
          ) : (
            <>
              <img
                src={CommonArrowRightIcon}
                className={
                  hasBetterSales
                    ? templateStyle.upArrowIcon
                    : templateStyle.downArrowIcon
                }
                alt=""
              />
              <p
                className={`${templateStyle.yesterdaySalesMessage} ${
                  hasBetterSales
                    ? templateStyle.positiveMessage
                    : templateStyle.negativeMessage
                }`}
              >
                {`${hasBetterSales ? "+" : ""}${formatToSoles(
                  salesData.totalSales.sales - salesData.yestardaySales.sales
                )} `}
                que {comparisonPeriodText}
              </p>
            </>
          )}
        </span>
      </div>
      <div className={style.graphContainer}>
        <BooleanButtons
          value={salesCardMeasurement}
          onChange={setSetsalesCardMeasurement}
        />
        <SalesBarGraph
          values={graphData}
          isQuantity={salesCardMeasurement === QUANTITY_MEASUREMENT}
        />
      </div>
    </div>
  );
};

export default CardWithGraph;
