import React, { useEffect, useState } from "react";
// Styles
// @ts-ignore
import styles from "./ServicesModal.module.css";

// Components
// @ts-ignore
import { ModalAcceptCancel } from "@viuti/recursos";

// Utils
import { formatToSoles } from "@Utilities/texts";
import BooleanButtons from "@Components/ui/atoms/BooleanButtons/BooleanButtons";
import { QUANTITY_MEASUREMENT } from "@Models/const/measurements";

const ServicesModal = ({ modalVisible, setModalVisible, data, title }) => {
  const [salesCardMeasurement, setSalesCardMeasurement] =
    useState(QUANTITY_MEASUREMENT);
  if (data.length === 0) {
    data = [{ id: 1, name: "Servicio 1", quantity: 10, price: 100 }];
  }

  const date = new Date();
  const currentMonth = date.toLocaleString("es-ES", { month: "long" });
  const formatedMonth =
    currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
  const currentYear = date.toLocaleString("es-ES", { year: "numeric" });

  // Ordena los datos de mayor a menor
  const dataOrdenada = data.sort((a, b) => {
    return b.quantity - a.quantity;
  });

  const totalQuantity = data.reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);

  const totalPrice = data.reduce((acc, item) => acc + item.price, 0);

  const formatNumber = (number: number): string => {
    return number.toFixed(2).padStart(5, "0");
  };

  const getBarWidth = (item) => {
    return salesCardMeasurement === QUANTITY_MEASUREMENT
      ? formatNumber((item.quantity / totalQuantity) * 100)
      : formatNumber((item.price / totalPrice) * 100);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setModalVisible(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  title = `${title} - ${formatedMonth} ${currentYear}`;

  return (
    /* @ts-ignore */
    /* eslint-disable-next-line */
    <ModalAcceptCancel
      title={title}
      visibility={{ isVisible: modalVisible, setIsVisible: setModalVisible }}
      showButtons={{ showButtonClose: true }}
      actionButtonClose={() => setModalVisible(false)}
    >
      <div className={styles.modalBody}>
        <BooleanButtons
          value={salesCardMeasurement}
          onChange={setSalesCardMeasurement}
        />
        <p className={styles.Modal__total}>
          Total de Ventas:{" "}
          <span>
            {salesCardMeasurement === QUANTITY_MEASUREMENT
              ? `${totalQuantity} uds.`
              : formatToSoles(totalPrice)}
          </span>
        </p>
        <div className={styles.modalBodyContainer}>
          {dataOrdenada.map((item, index) => {
            const tempName = item.name ? item.name : "Sin nombre";
            return (
              <div
                className={styles.itemContainer}
                style={{
                  marginBottom:
                    index < dataOrdenada.length - 1 ? "11px" : "0px",
                }}
                key={item.id}
              >
                <div className={styles.itemTitleContainer}>
                  <p style={{ margin: 0, fontSize: 12 }}>
                    {tempName.charAt(0).toUpperCase() + tempName.slice(1)}
                    {salesCardMeasurement === QUANTITY_MEASUREMENT
                      ? ` (${item.quantity})`
                      : ` (${formatToSoles(item.price)})`}
                  </p>
                </div>
                <div className={styles.Item__Sale}>
                  <div className={styles.itemBarContainer}>
                    <div
                      style={{
                        width: `${getBarWidth(item)}%`,
                        height: "100%",
                        backgroundColor: "#937CF4",
                        transition: "all 100ms ease-in-out",
                      }}
                    />
                  </div>
                  <p className={styles.Item__Sale__Percentage}>
                    {getBarWidth(item)}%
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ModalAcceptCancel>
  );
};

export default ServicesModal;
