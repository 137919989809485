import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";
import { baseUrl, config } from "@Services/constGlobals";

interface GoalPayload {
  goal: number;
  startDate: string;
  endDate: string;
  type: number;
}

export const createGoalService = async (payload: GoalPayload) => {
  const url = `${baseUrl}/Goal`;
  try {
    const fijar_meta_semanal = 2;
    trackUserAction(fijar_meta_semanal);
    const response: IResponse = await axios.post(url, payload, config);

    return {
      status: response.status,
      message: response.message,
      data: response.data.data,
    };
  } catch (error) {
    return {
      status: 400,
      message:
        error.response?.data?.message ||
        "Ups! ocurrió un problema, vuelva a intentarlo mas tarde.",
      data: null,
    };
  }
};
