export const capitalizeFirstLetter = (text: string) => {
  if (text === null || text === undefined) {
    return ""; // Devuelve una cadena vacía si el texto es nulo
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const capitalizeFirstLetterOfEachWord = (text: string) => {
  if (text === null) {
    return ""; // Devuelve una cadena vacía si el texto es nulo
  }

  const words = text.split(" ");
  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return ""; // Devuelve una cadena vacía si la palabra está vacía
    }
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  return capitalizedWords.join(" ");
};

export const capitalizeFirstLetterOfEachWordExceptFirst = (text: string) => {
  if (text === null) {
    return ""; // Devuelve una cadena vacía si el texto es nulo
  }

  const words = text.split(" ");
  const capitalizedWords = words.map((word, index) => {
    if (index === 0) {
      return word; // Devuelve la primera palabra sin modificar
    }
    if (word.length === 0) {
      return ""; // Devuelve una cadena vacía si la palabra está vacía
    }
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  return capitalizedWords.join(" ");
};

export const capitalizeFirstLetterOfEachWordExceptFirstAndLast = (
  text: string
) => {
  if (text === null) {
    return ""; // Devuelve una cadena vacía si el texto es nulo
  }

  const words = text.split(" ");
  const capitalizedWords = words.map((word, index) => {
    if (index === 0 || index === words.length - 1) {
      return word; // Devuelve la primera y última palabra sin modificar
    }
    if (word.length === 0) {
      return ""; // Devuelve una cadena vacía si la palabra está vacía
    }
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  return capitalizedWords.join(" ");
};

export const formatDates = (fechaStr) => {
  if (!fechaStr) return "";
  const meses = [
    "ENE",
    "FEB",
    "MAR",
    "ABR",
    "MAY",
    "JUN",
    "JUL",
    "AGO",
    "SEP",
    "OCT",
    "NOV",
    "DIC",
  ];

  const fecha = new Date(fechaStr);

  const dia = fecha.getDate();
  const mes = meses[fecha.getMonth()];
  const año = fecha.getFullYear();

  return `${dia} - ${mes} - ${año}`;
};

export const formatToSoles = (
  number: number | string,
  includePrefix: boolean = true
) => {
  number = Number(number);

  if (typeof number !== "number" || isNaN(number)) {
    return "S/ 0.00";
  }

  const formatter = new Intl.NumberFormat("es-PE", {
    style: "currency",
    currency: "PEN",
    currencyDisplay: "symbol",
  });

  const formatterWithoutSymbol = number.toLocaleString("es-PE", {
    minimumFractionDigits: 2,
  });

  return includePrefix ? formatter.format(number) : formatterWithoutSymbol;
};

export const formatToSolesWithoutCurrency = (number: number | string) => {
  if (number === "") {
    return "--.--";
  }

  number = Number(number);
  if (number === null || number === undefined) {
    return "";
  }

  const formatter = new Intl.NumberFormat("es-PE", {
    style: "currency",
    currency: "PEN",
  });

  return formatter.format(number).slice(3);
};
