// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tmmAAn7uHo_07aJ_yatg {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 510px;

  @media screen and (max-width: 660px) {
    min-width: auto;
    width: 100%;
  }
}

.YnjwojO3weeMi5H7FGMJ {
  color: #45348e;
  font-family: Mulish;
  font-size: 18px;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/GoalCard/GoalCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;;EAEhB;IACE,eAAe;IACf,WAAW;EACb;AACF;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".cardContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  min-width: 510px;\n\n  @media screen and (max-width: 660px) {\n    min-width: auto;\n    width: 100%;\n  }\n}\n\n.cardTitle {\n  color: #45348e;\n  font-family: Mulish;\n  font-size: 18px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContainer": `tmmAAn7uHo_07aJ_yatg`,
	"cardTitle": `YnjwojO3weeMi5H7FGMJ`
};
export default ___CSS_LOADER_EXPORT___;
