import React, { useState } from "react";
import style from "../styles/UnsetGoal.module.css";
import {
  ButtonWithoutIcon,
  TextInput,
  Icon,
  validateModuleAccess,
  // @ts-ignore
} from "@viuti/recursos";
import { GoalIcon, TimesIcon } from "@Models/icons";
import { updateGoalAdapter } from "@Adapters/updateGoal.adapter";
import { STATUS_OK } from "@Models/httpsStatus";
import { useAppDispatch } from "@Hooks/store";
import { formatToSoles } from "@Utilities/texts";
import { UPSERT_GOALS_UUID } from "@Models/const/securityAccess";

const UnsetGoal = ({
  periodType,
  idGoal,
  goalValue,
  setGoalValue,
  inputGoalValue,
  setInputGoalValue,
  isInputVisible,
  setIsInputVisible,
  isMonth,
}) => {
  const dispatch = useAppDispatch();

  const [isUpdating, setIsUpdating] = useState(false);

  const handleGoalChange = (e) => {
    const onlyNumbers = e.target.value.replace(/[^0-9.]/g, "");
    if (onlyNumbers === ".") {
      setInputGoalValue("0.");
    }
    setInputGoalValue(onlyNumbers);
  };

  const handleGoalBlur = (e) => {
    if (inputGoalValue === "") {
      setInputGoalValue(0);
    }

    const onlyNumbers = e.target.value.replace(/[^0-9.]/g, "");
    setInputGoalValue(formatToSoles(onlyNumbers, false));
  };

  const handleGoalSubmit = async (e) => {
    if (inputGoalValue == 0) {
      setIsInputVisible(true);
    } else {
      setIsUpdating(true);
      const response = await updateGoalAdapter(
        dispatch,
        idGoal,
        inputGoalValue,
        periodType
      );

      if (response.status === STATUS_OK) {
        setGoalValue(inputGoalValue);
        setIsInputVisible(false);
      }
      setIsUpdating(false);
    }
  };

  return (
    <section className={style.cardContent}>
      <div className={style.iconContainer}>
        <Icon path={GoalIcon} color={"#ACACAC"} size={56} />
      </div>
      <div className={style.goalContent}>
        <span className={style.cardHeaderContainer}>
          <h3 className={style.cardHeader}>
            {isMonth
              ? "¡Establece una meta para cumplir este mes!"
              : "¡Establece una meta para cumplir esta semana!"}
          </h3>
          {isInputVisible && (
            <button
              onClick={() => {
                setIsInputVisible(false);
              }}
              className={style.closeButton}
            >
              <Icon path={TimesIcon} color={"#B4B4B4"} size={16} />
            </button>
          )}
        </span>
        {!isInputVisible ? (
          <p className={style.goalSubtitle}>
            {isMonth
              ? "Ingresa el monto en soles que deseas generar este mes y revisar tu progreso"
              : "Ingresa el monto en soles que deseas generar esta semana y revisar tu progreso"}
          </p>
        ) : (
          <TextInput
            label="Monto"
            handleChange={handleGoalChange}
            handleBlur={handleGoalBlur}
            prefix="S/ "
            inputProps={{
              placeholder: "0.00",
              value: inputGoalValue,
              type: "text",
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  validateModuleAccess(UPSERT_GOALS_UUID) &&
                    handleGoalSubmit(e);
                }
              },
            }}
          />
        )}
        <ButtonWithoutIcon
          isPrimary={true}
          textBttn="Establecer meta"
          handleClick={(e) =>
            validateModuleAccess(UPSERT_GOALS_UUID) && handleGoalSubmit(e)
          }
          isDisabled={
            (inputGoalValue === 0 && isInputVisible) ||
            (inputGoalValue === "" && isInputVisible) ||
            isUpdating
          }
          width={"fit-content"}
          isLoading={isUpdating}
        />
      </div>
    </section>
  );
};

export default UnsetGoal;
