import React from "react";
import style from "../styles/SettedGoal.module.css";
// @ts-ignore
import { ButtonWithoutIcon, validateModuleAccess } from "@viuti/recursos";
import ProgressCircle from "../../ProgressCircle/ProgressCircle";
import { formatToSoles } from "@Utilities/texts";
import { UPSERT_GOALS_UUID } from "@Models/const/securityAccess";

const SettedGoal = ({ salesValue, goalValue, setIsInputVisible, isMonth }) => {
  const localGoalValue = Number(goalValue?.replace(/[^0-9.]/g, ""));

  const handleGoalSubmit = () => {
    setIsInputVisible(true);
  };

  return (
    <section className={style.cardContent}>
      <div className={style.iconContainer}>
        <ProgressCircle percentageValue={(salesValue / localGoalValue) * 100} />
      </div>
      <div className={style.goalContent}>
        <div className={style.goalsContainer}>
          <p className={style.goalMessage}>
            {isMonth ? "Meta del mes:" : "Meta de la semana:"}
            <span className={style.goalValue}>
              {formatToSoles(localGoalValue)}
            </span>
          </p>
          <p className={style.goalMessage}>
            Avance:
            <span className={style.goalValue}>{formatToSoles(salesValue)}</span>
          </p>
        </div>

        <ButtonWithoutIcon
          isPrimary={true}
          textBttn="Editar meta"
          handleClick={(e) =>
            validateModuleAccess(UPSERT_GOALS_UUID) && handleGoalSubmit()
          }
          isDisabled={false}
          width={"fit-content"}
        />
      </div>
    </section>
  );
};

export default SettedGoal;
