import { WEEKLY_GOAL } from "@Models/const/goalType";
import { STATUS_OK } from "@Models/httpsStatus";
import { AppDispatch } from "@ReduxService/index";
import {
  updateNotificationMessage,
  updateNotificationStatus,
} from "@ReduxService/states/notificationSlice";
import { createGoalService } from "@Services/updateGoal/createGoal";
import { updateGoalService } from "@Services/updateGoal/updateGoal";

export const updateGoalAdapter = async (
  dispatch: AppDispatch,
  id: number,
  newGoal: number,
  period: number
) => {
  try {
    let response;
    const startOfWeek = new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
    )
      .toISOString()
      .split("T")[0];
    const endOfWeek = new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
    )
      .toISOString()
      .split("T")[0];

    const startOfMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    )
      .toISOString()
      .split("T")[0];
    const endOfMonth = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    )
      .toISOString()
      .split("T")[0];

    if (id === null) {
      const payload = {
        goal: newGoal,
        startDate: period === WEEKLY_GOAL ? startOfWeek : startOfMonth,
        endDate: period === WEEKLY_GOAL ? endOfWeek : endOfMonth,
        type: period,
      };

      response = await createGoalService(payload);
    } else {
      const payload = {
        goal: newGoal,
        goalId: id,
      };

      response = await updateGoalService(payload);
    }

    if (response.status !== STATUS_OK) {
      dispatch(
        updateNotificationMessage(
          "Ups! ocurrió un problema, vuelva a intentarlo mas tarde."
        )
      );
      dispatch(updateNotificationStatus(response.status));
    }

    return response;
  } catch (error) {}
};
