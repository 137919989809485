import React, { useEffect, useRef, useState } from "react";

// Styles
// @ts-ignore
import templateStyle from "../CardTemplate/CardContent.module.css";
// @ts-ignore
import style from "./CardSalesMade.module.css";

// Functions
import { formatToSoles } from "@Utilities/texts";

// Models
import { CommonArrowRightIcon } from "@Models/icons";

const CardSalesMade = ({
  totalSales,
  averageTicket,
  hasBetterSales,
  comparativeSales,
  formatComparativeSales = true,
  comparisonPeriodText,
}) => {
  const [cardSize, setCardSize] = useState({
    width: 0,
    height: 0,
  });
  const cardRef = useRef(null);
  const LARGE_BREAKPOINT = 340;

  // modify the size of the card to fit the content every time the window is resized
  useEffect(() => {
    const updateCardSize = () => {
      if (cardRef.current) {
        const { width, height } = cardRef.current.getBoundingClientRect();
        setCardSize({ width, height });
      }
    };

    updateCardSize();

    window.addEventListener("resize", () => updateCardSize);

    return () => {
      window.removeEventListener("resize", () => updateCardSize);
    };
  }, [cardRef]);

  return (
    <div className={`${style.cardContainer}`} ref={cardRef}>
      <span className={style.totalSalesContainer}>
        <strong className={templateStyle.totalSalesValue}>{totalSales}</strong>
        <p
          className={`${templateStyle.totalSalesMessage} ${style.totalSalesMessage}`}
        >
          Ventas hasta el momento
        </p>
      </span>
      <div
        className={`${style.performanceContainer} ${
          cardSize.width > LARGE_BREAKPOINT
            ? style.performanceLargeContainer
            : ""
        }`}
      >
        <span
          className={`${style.ticketSalesContainer} ${
            cardSize.width > LARGE_BREAKPOINT
              ? style.ticketSalesLargeContainer
              : ""
          }`}
        >
          <p
            className={`${templateStyle.totalSalesMessage} ${style.totalSalesMessage}`}
          >
            Ticket promedio
          </p>
          <p className={style.ticketSalesValue}>
            {formatToSoles(averageTicket)}
          </p>
        </span>
        <span className={templateStyle.yestardaySalesContainer}>
          {comparativeSales === 0 ? (
            <p className={`${templateStyle.yesterdaySalesMessage} `}>
              No hubo cambios
            </p>
          ) : (
            <>
              <img
                src={CommonArrowRightIcon}
                className={
                  hasBetterSales
                    ? templateStyle.upArrowIcon
                    : templateStyle.downArrowIcon
                }
                alt=""
              />
              <p
                className={`${templateStyle.yesterdaySalesMessage} ${
                  hasBetterSales
                    ? templateStyle.positiveMessage
                    : templateStyle.negativeMessage
                }`}
              >
                {`${hasBetterSales ? "+" : ""}${
                  formatComparativeSales
                    ? formatToSoles(comparativeSales)
                    : comparativeSales
                }`}{" "}
                ventas que {comparisonPeriodText}
              </p>
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default CardSalesMade;
