import React from "react";
// @ts-ignore
import style from "./BooleanButtons.module.css";
import {
  MONEY_MEASUREMENT,
  QUANTITY_MEASUREMENT,
} from "@Models/const/measurements";

const BooleanButtons = ({ value, onChange }) => {
  return (
    <div className={style.buttonContainers}>
      <button
        onClick={() => {
          onChange(QUANTITY_MEASUREMENT);
        }}
        className={`
                ${style.button}
                ${style.button__left}
                ${value === QUANTITY_MEASUREMENT ? style.button__active : ""}`}
      >
        Cantidad
      </button>
      <button
        onClick={() => {
          onChange(MONEY_MEASUREMENT);
        }}
        className={`
                ${style.button}
                ${style.button__right}
                ${value === MONEY_MEASUREMENT ? style.button__active : ""}`}
      >
        Dinero
      </button>
    </div>
  );
};

export default BooleanButtons;
