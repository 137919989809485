// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gR4hhClfc5KmKtLkQa4D {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.OYyfsbuJTfhANfOUPpzH {
  color: #b554cc;
  font-family: Mulish;
  font-size: 24px;
  font-weight: 800;
}

.F11oNYqJw50NgQRV2c28 {
  color: #392b75;
  font-family: Mulish;
  font-size: 24px;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/SectionTitle/SectionTitle.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".headerContainer {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 10px;\n}\n\n.periodTitle {\n  color: #b554cc;\n  font-family: Mulish;\n  font-size: 24px;\n  font-weight: 800;\n}\n\n.todayDateTitle {\n  color: #392b75;\n  font-family: Mulish;\n  font-size: 24px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": `gR4hhClfc5KmKtLkQa4D`,
	"periodTitle": `OYyfsbuJTfhANfOUPpzH`,
	"todayDateTitle": `F11oNYqJw50NgQRV2c28`
};
export default ___CSS_LOADER_EXPORT___;
