import { IDailyData } from "@Models/interfaces/sales";

export const MOCK_DAILY_DATA: IDailyData = {
  customerDetails: [],
  sales: {
    todaySales: 0,
    averageTickets: 0,
    totalSales: {
      sales: 0,
      salesQuantity: 0,
      customers: 0,
    },
    yestardaySales: {
      sales: 0,
      salesQuantity: 0,
      customers: 0,
    },
    giftcards: {
      total: 0,
      totalAmount: 0,
    },
    yestardayGiftcards: {
      total: 0,
      totalAmount: 0,
    },
    products: {
      total: 0,
      totalAmount: 0,
    },
    yestardayProducts: {
      total: 0,
      totalAmount: 0,
    },
    packages: {
      total: 0,
      totalAmount: 0,
    },
    yestardayPackages: {
      total: 0,
      totalAmount: 0,
    },
    services: {
      total: 0,
      totalAmount: 0,
    },
    yestardayServices: {
      total: 0,
      totalAmount: 0,
    },
  },
  totalActiveCustomers: 0,
};
