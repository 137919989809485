import App from "@Pages/app";
import "./interceptors/timeout.interceptor";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export default function Root(props) {
  const ingresa_vista_estadisticas = 1;
  trackUserAction(ingresa_vista_estadisticas);
  return <App />;
}
