import React, { useEffect, useRef, useState } from "react";

// Styles
// @ts-ignore
import templateStyle from "../CardTemplate/CardContent.module.css";
// @ts-ignore
import style from "./CardSimpleSales.module.css";

// Functions
import { formatToSoles } from "@Utilities/texts";

// Models
import { CommonArrowRightIcon } from "@Models/icons";

const CardSimpleSales = ({
  label,
  data,
  isMoneyFormat = false,
}: {
  label: string;
  data: { currentMonth: number; previousMonth: number };
  isMoneyFormat?: boolean;
}) => {
  const [cardSize, setCardSize] = useState({
    width: 0,
    height: 0,
  });
  const cardRef = useRef(null);
  const LARGE_BREAKPOINT = 260;

  // modify the size of the card to fit the content every time the window is resized
  useEffect(() => {
    const updateCardSize = () => {
      if (cardRef.current) {
        const { width, height } = cardRef.current.getBoundingClientRect();
        setCardSize({ width, height });
      }
    };

    updateCardSize();

    window.addEventListener("resize", () => updateCardSize);

    return () => {
      window.removeEventListener("resize", () => updateCardSize);
    };
  }, [cardRef]);

  const hasBetterSales = data.currentMonth > data.previousMonth;

  return (
    <div className={`${style.cardContainer}`} ref={cardRef}>
      <span className={style.totalSalesContainer}>
        <strong className={style.totalSalesValue}>
          {isMoneyFormat ? formatToSoles(data.currentMonth) : data.currentMonth}
        </strong>
        <p className={`${style.totalSalesMessage} ${style.totalSalesMessage}`}>
          {label}
        </p>
      </span>
      <div
        className={`${style.performanceContainer} ${
          cardSize.width > LARGE_BREAKPOINT
            ? style.performanceLargeContainer
            : ""
        }`}
      >
        <span className={templateStyle.yestardaySalesContainer}>
          {data.currentMonth - data.previousMonth === 0 ? (
            <p className={`${templateStyle.yesterdaySalesMessage} `}>
              No hubo cambios
            </p>
          ) : (
            <>
              <img
                src={CommonArrowRightIcon}
                className={
                  hasBetterSales
                    ? templateStyle.upArrowIcon
                    : templateStyle.downArrowIcon
                }
                alt=""
              />
              <p
                className={`${templateStyle.yesterdaySalesMessage} ${
                  hasBetterSales
                    ? templateStyle.positiveMessage
                    : templateStyle.negativeMessage
                }`}
              >
                {`${hasBetterSales ? "+" : ""}${
                  isMoneyFormat
                    ? formatToSoles(data.currentMonth - data.previousMonth)
                    : data.currentMonth - data.previousMonth
                }`}{" "}
                que el mes anterior
              </p>
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default CardSimpleSales;
