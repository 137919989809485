import React, { useEffect, useRef, useState } from "react";

// Styles
// @ts-ignore
import templateStyle from "../CardTemplate/CardContent.module.css";
// @ts-ignore
import style from "./CardTotalSales.module.css";

// Components
// @ts-ignore
import { Icon } from "@viuti/recursos";

// Functions
import { formatToSoles } from "@Utilities/texts";

// Models
import { CashCheckIcon, CommonArrowRightIcon } from "@Models/icons";

const CardTotalSales = (salesData) => {
  const [cardSize, setCardSize] = useState({
    width: 0,
    height: 0,
  });
  const cardRef = useRef(null);
  const LARGE_BREAKPOINT = 260;

  // modify the size of the card to fit the content every time the window is resized
  useEffect(() => {
    const updateCardSize = () => {
      if (cardRef.current) {
        const { width, height } = cardRef.current.getBoundingClientRect();
        setCardSize({ width, height });
      }
    };

    updateCardSize();

    window.addEventListener("resize", () => updateCardSize);

    return () => {
      window.removeEventListener("resize", () => updateCardSize);
    };
  }, [cardRef]);

  const isBetterSales =
    salesData.sales.totalSales.currentMonth >
    salesData.sales.totalSales.previousMonth;

  return (
    <div className={`${style.cardContainer}`} ref={cardRef}>
      <span className={style.totalSalesContainer}>
        <span className={style.cashIcon}>
          <Icon path={CashCheckIcon} color={"#00B71D"} size={36} />
        </span>
        <strong className={templateStyle.totalSalesValue}>
          {formatToSoles(salesData.totalSales.sales)}
        </strong>
      </span>
      {!salesData?.isPeriod ? (
        <>
          <hr className={style.divisor} />
          <div
            className={`${style.performanceContainer} ${
              cardSize.width > LARGE_BREAKPOINT
                ? style.performanceLargeContainer
                : ""
            }`}
          >
            <span className={templateStyle.yestardaySalesContainer}>
              {salesData.sales.totalSales.currentMonth -
                salesData.sales.totalSales.previousMonth ===
              0 ? (
                <p className={`${templateStyle.yesterdaySalesMessage} `}>
                  No hubo cambios
                </p>
              ) : (
                <>
                  <img
                    src={CommonArrowRightIcon}
                    className={
                      isBetterSales
                        ? templateStyle.upArrowIcon
                        : templateStyle.downArrowIcon
                    }
                    alt=""
                  />
                  <p
                    className={`${templateStyle.yesterdaySalesMessage} ${
                      isBetterSales
                        ? templateStyle.positiveMessage
                        : templateStyle.negativeMessage
                    }`}
                  >
                    {`${isBetterSales ? "+" : ""}${formatToSoles(
                      salesData.sales.totalSales.currentMonth -
                        salesData.sales.totalSales.previousMonth
                    )}`}{" "}
                    que el mes anterior
                  </p>
                </>
              )}
            </span>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CardTotalSales;
