// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tg8fU14gkOyJxbNWxpkm {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.FEIcu4JZMODwLVOMZdPc {
  padding: 4px 16px;
  border: 1px solid #aaaaaa;
  color: #888888;
  font-family: "Mulish";
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}

.wZhI72uIfZF9iQUGNUFm {
  background-color: #937cf41a;
  color: #937cf4;
  border: 1px solid #937cf4;
}

.k0b38i2hw8t91Rmq6EnB {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tdADcOjXJYmxgr4tVP8T {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/BooleanButtons/BooleanButtons.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,2BAA2B;EAC3B,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC","sourcesContent":[".buttonContainers {\n  width: fit-content;\n  height: fit-content;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.button {\n  padding: 4px 16px;\n  border: 1px solid #aaaaaa;\n  color: #888888;\n  font-family: \"Mulish\";\n  font-weight: 500;\n  font-size: 12px;\n  line-height: 20px;\n  cursor: pointer;\n  transition: all 100ms ease-in-out;\n}\n\n.button__active {\n  background-color: #937cf41a;\n  color: #937cf4;\n  border: 1px solid #937cf4;\n}\n\n.button__left {\n  border-top-left-radius: 4px;\n  border-bottom-left-radius: 4px;\n}\n\n.button__right {\n  border-top-right-radius: 4px;\n  border-bottom-right-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainers": `tg8fU14gkOyJxbNWxpkm`,
	"button": `FEIcu4JZMODwLVOMZdPc`,
	"button__active": `wZhI72uIfZF9iQUGNUFm`,
	"button__left": `k0b38i2hw8t91Rmq6EnB`,
	"button__right": `tdADcOjXJYmxgr4tVP8T`
};
export default ___CSS_LOADER_EXPORT___;
