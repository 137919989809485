import React from "react";

// Style
// @ts-ignore
import style from "./WeekModule.module.css";

// Components
import SectionTitle from "@Components/ui/atoms/SectionTitle/SectionTitle";
import CardTemplate from "@Components/ui/atoms/CardTemplate/CardTemplate";
import CardWithGraph from "@Components/ui/atoms/CardWithGraph/CardWithGraph";
import GoalCard from "@Components/ui/atoms/GoalCard/GoalCard";
import CardSalesMade from "@Components/ui/atoms/CardSalesMade/CardSalesMade";

// Hooks
import { useAppSelector } from "@Hooks/store";
import { useMediaQuery } from "@Hooks/useMediaQuerie";
import { WEEKLY_GOAL } from "@Models/const/goalType";

const WeekModule = () => {
  const salesState = useAppSelector((state) => state.sales);
  const isRowBased = useMediaQuery("(max-width: 1200px)");

  // Styles
  const styles = {
    container: (isRowBased) => ({
      width: isRowBased ? "100%" : "calc(50% - 15px)",
      minWidth: "200px",
    }),
  };

  return (
    <>
      <SectionTitle idPeriod={2} />
      <section className={style.sectionCardsContainer}>
        <section className={style.firstRowContainer}>
          <CardTemplate title="Ventas" flex={1}>
            <CardWithGraph
              salesData={salesState.weeklyData.sales}
              comparisonPeriodText={"la semana anterior"}
            />
          </CardTemplate>
          <GoalCard
            title="Meta semanal"
            flex={1}
            data={salesState.weeklyData}
            periodType={WEEKLY_GOAL}
            isMonth={false}
          />
        </section>
        <CardTemplate
          title="Ventas realizadas"
          componentCustomStyle={styles.container(isRowBased)}
          flex={0.5}
        >
          <CardSalesMade
            totalSales={salesState.weeklyData.sales.weeklySales}
            averageTicket={salesState.weeklyData.sales.averageTickets}
            hasBetterSales={
              Number(salesState.weeklyData.sales.totalSales.salesQuantity) >
              Number(salesState.weeklyData.sales.yestardaySales.salesQuantity)
            }
            comparativeSales={
              salesState.weeklyData.sales.totalSales.salesQuantity -
              salesState.weeklyData.sales.yestardaySales.salesQuantity
            }
            formatComparativeSales={false}
            comparisonPeriodText={"la semana anterior"}
          />
        </CardTemplate>
      </section>
    </>
  );
};

export default WeekModule;
