import React, { useEffect, useRef, useState } from "react";
import style from "./MonthSalesCalendar.module.css";
import {
  MONEY_MEASUREMENT,
  QUANTITY_MEASUREMENT,
} from "@Models/const/measurements";
import BooleanButtons from "../BooleanButtons/BooleanButtons";
import { formatToSoles } from "@Utilities/texts";
import { HiMiniStar } from "react-icons/hi2";

interface Sale {
  date: string;
  sales: number;
  quantity: number;
  total: number;
}

interface IMonthSalesCalendar {
  salesData: Sale[];
}

const MonthSalesCalendar: React.FC<IMonthSalesCalendar> = ({ salesData }) => {
  const [selectedFormat, setSelectedFormat] = useState(QUANTITY_MEASUREMENT);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const calendarHeaderRef = useRef<HTMLDivElement>(null);
  const calendarGridHeaderRef = useRef<HTMLDivElement>(null);
  const calendarGridRef = useRef<HTMLDivElement>(null);

  const values =
    selectedFormat === MONEY_MEASUREMENT
      ? salesData.map((sale) => {
          return { date: sale.date, value: sale.total };
        })
      : salesData.map((sale) => {
          return { date: sale.date, value: sale.sales };
        });

  const getDaysInMonth = (month: number, year: number): number => {
    return new Date(year, month + 1, 0).getDate();
  };

  const generateCalendar = (): (Date | null)[] => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const daysInMonth = getDaysInMonth(month, year);
    const firstDayIndex = (new Date(year, month, 1).getDay() + 6) % 7; // Make Monday the first day
    const daysArray: (Date | null)[] = [];

    const prevMonthDays = getDaysInMonth(
      month - 1 < 0 ? 11 : month - 1,
      month - 1 < 0 ? year - 1 : year
    );
    for (let i = firstDayIndex; i > 0; i--) {
      daysArray.push(
        new Date(year, month - 1 < 0 ? 11 : month - 1, prevMonthDays - i + 1)
      );
    }

    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(new Date(year, month, i));
    }

    const nextMonthStart =
      daysArray.length % 7 === 0 ? 0 : 7 - (daysArray.length % 7);
    for (let i = 1; i <= nextMonthStart; i++) {
      daysArray.push(new Date(year, month + 1 > 11 ? 0 : month + 1, i));
    }

    return daysArray;
  };

  const changeMonth = (direction: number) => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() + direction))
    );
  };

  const parseDate = (dateString: string): Date => {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(Date.UTC(year, month - 1, day)); // Crear la fecha en UTC
  };

  const getSalesForDay = (date: Date): number => {
    const daySales = values.find((sale) => {
      const saleDate = parseDate(sale.date);
      return (
        saleDate.getUTCFullYear() === date.getUTCFullYear() &&
        saleDate.getUTCMonth() === date.getUTCMonth() &&
        saleDate.getUTCDate() === date.getUTCDate()
      );
    });
    return daySales ? daySales.value : 0;
  };

  const getMaxSales = (): number => {
    return Math.max(...values.map((sale) => sale.value));
  };

  const getMaxSalesDay = (): Date | undefined => {
    const maxSales = getMaxSales();
    const maxSale = values.find((sale) => sale.value === maxSales);
    return maxSale ? parseDate(maxSale.date) : undefined;
  };

  const getOpacity = (sales: number): number => {
    const maxSales = getMaxSales();
    return sales / maxSales;
  };

  const getTextColor = (opacity: number): string => {
    return opacity > 0.5 ? "white" : "black";
  };

  const renderTooltip = (date: Date): string => {
    const salesVolume = getSalesForDay(date);
    const message =
      selectedFormat === MONEY_MEASUREMENT
        ? `${formatToSoles(salesVolume)}`
        : `${salesVolume} unidades`;
    return `Ventas: ${message}`;
  };

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    date: Date,
    disabled: boolean
  ) => {
    if (disabled) return;
    const tooltip = document.createElement("div");
    tooltip.className = style.tooltip;
    tooltip.innerText = renderTooltip(date);
    document.body.appendChild(tooltip);
    const rect = event.currentTarget.getBoundingClientRect();
    tooltip.style.left = `${
      rect.left + window.scrollX + rect.width / 2 - 55
    }px`;
    tooltip.style.top = `${rect.top + window.scrollY - rect.height + 10}px`;
  };

  const handleMouseLeave = () => {
    const tooltip = document.querySelector(`.${style.tooltip}`);
    if (tooltip) {
      tooltip.remove();
    }
  };

  const daysArray = generateCalendar();
  const maxSalesDay = getMaxSalesDay();
  const today = new Date();

  useEffect(() => {
    const handleResize = () => {
      const headerHeight = calendarHeaderRef.current?.scrollHeight || 0;
      const gridHeaderHeight = calendarGridHeaderRef.current?.scrollHeight || 0;
      const totalHeight = headerHeight + gridHeaderHeight;
      calendarGridRef.current?.setAttribute(
        "style",
        `height: calc(100% - ${totalHeight}px - 20px)`
      );
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [daysArray]);

  return (
    <div className={style.calendar__container}>
      <div className={style.calendar__header} ref={calendarHeaderRef}>
        <BooleanButtons value={selectedFormat} onChange={setSelectedFormat} />
      </div>
      <div className={style.calendar__grid} ref={calendarGridHeaderRef}>
        {["LUN", "MAR", "MIÉ", "JUE", "VIE", "SÁB", "DOM"].map((day) => (
          <div key={day} className={style.calendar__day__header}>
            <p>{day}</p>
          </div>
        ))}
      </div>
      <div className={style.calendar__grid} ref={calendarGridRef}>
        {daysArray.map((date, index) => {
          const uniqueKey = date ? date.toDateString() : index;
          const salesVolume = getSalesForDay(date);
          const opacity = getOpacity(salesVolume);
          const isOutsideCurrentMonth =
            date && date.getMonth() !== currentMonth.getMonth();
          const isMaxSalesDay =
            date &&
            maxSalesDay &&
            date.getUTCFullYear() === maxSalesDay.getUTCFullYear() &&
            date.getUTCMonth() === maxSalesDay.getUTCMonth() &&
            date.getUTCDate() === maxSalesDay.getUTCDate();
          const isPastDayWithoutSales =
            date &&
            date.getUTCDate() < today.getUTCDate() &&
            salesVolume === 0 &&
            !isOutsideCurrentMonth;
          const isTodayDay =
            date && date.toDateString() === today.toDateString();

          return (
            <div
              key={uniqueKey}
              className={`${style.calendar__day} ${
                isOutsideCurrentMonth ? style.outside__day : ""
              }`}
              onMouseEnter={(e) =>
                date && handleMouseEnter(e, date, isOutsideCurrentMonth)
              }
              onMouseLeave={handleMouseLeave}
            >
              <div
                className={`${style.calendar__day_content} ${
                  isTodayDay ? style.calendar__day_content__today : ""
                }`}
                style={{
                  backgroundColor: `rgba(147, 124, 244, ${opacity})`,
                  border:
                    opacity > 0.2
                      ? "none"
                      : "1px solid rgba(205, 205, 205, 0.2)",
                  color: getTextColor(opacity),
                }}
              >
                {date ? date.getUTCDate() : ""}
                {isMaxSalesDay && (
                  <span className={style.max_sales_bubble}>
                    <HiMiniStar size={8} color="#CEB700" />
                  </span>
                )}
                {isPastDayWithoutSales && (
                  <span className={style.no_sales_bubble}>!</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonthSalesCalendar;
