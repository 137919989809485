// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fFLbugOC3kM0kpuQ_1Fp {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 24px 0px; */
  gap: 20px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
.RKRqoDQsUh1Im1RwMXre {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.nf9qH52TnXVvnYseQicK {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* @media screen and (width < 1200px) {
  .viewContainer {
    max-width: 700px;
    margin: auto;
  }
}

@media screen and (width < 900px) {
  .viewContainer {
    margin: none;
    padding: none;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/pages/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,SAAS;EACT,kBAAkB;;EAElB;IACE,aAAa;EACf;AACF;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;;;;;;;;;;;;GAYG","sourcesContent":[".viewContainer {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  /* padding: 24px 0px; */\n  gap: 20px;\n  overflow-y: scroll;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n.containerPeriod {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n.module__container {\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n}\n\n/* @media screen and (width < 1200px) {\n  .viewContainer {\n    max-width: 700px;\n    margin: auto;\n  }\n}\n\n@media screen and (width < 900px) {\n  .viewContainer {\n    margin: none;\n    padding: none;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewContainer": `fFLbugOC3kM0kpuQ_1Fp`,
	"containerPeriod": `RKRqoDQsUh1Im1RwMXre`,
	"module__container": `nf9qH52TnXVvnYseQicK`
};
export default ___CSS_LOADER_EXPORT___;
