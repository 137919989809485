import React, { useEffect, useState } from "react";
import style from "./MonthModule.module.css";
import SectionTitle from "@Components/ui/atoms/SectionTitle/SectionTitle";
import CardTemplate from "@Components/ui/atoms/CardTemplate/CardTemplate";
import { useAppSelector } from "@Hooks/store";
import GoalCard from "@Components/ui/atoms/GoalCard/GoalCard";
import CardTotalSales from "@Components/ui/atoms/CardTotalSales/CardTotalSales";
import MonthGraph from "@Components/ui/atoms/MonthGraph/MonthGraph";
import CardSimpleSales from "@Components/ui/atoms/CardSimpleSales/CardSimpleSales";
import CardHorBarGraph from "@Components/ui/atoms/CardHorBarGraph/CardHorBarGraph";
// import CardGiftcards from "@Components/ui/atoms/CardGiftcards/CardGiftcards";
import ServicesModal from "@Components/ui/molecules/ServicesModal/ServicesModal";
import { MONTHLY_GOAL } from "@Models/const/goalType";
import MonthSalesCalendar from "@Components/ui/atoms/MonthSalesCalendar/MonthSalesCalendar";
import { MAX_WIDTH_MOBILE } from "@Models/const/responsive";

const MonthModule = () => {
  const salesState = useAppSelector((state) => state.sales);
  const [screenSize, setScreenSize] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("Servicios");

  const setModalContent = (data: [], title: string) => {
    setModalData(data);
    setModalTitle(title);
    setModalVisible(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <SectionTitle idPeriod={3} />
      <section className={style.sectionCardsContainer}>
        <div className={style.firstRowContainer}>
          <CardTemplate title="Ventas" flex={1}>
            <CardTotalSales {...salesState.monthlyData.sales} />
          </CardTemplate>
          <GoalCard
            title="Meta mensual"
            flex={1}
            data={salesState.monthlyData}
            periodType={MONTHLY_GOAL}
            isMonth={true}
          />
        </div>
        <div className={style.monthSalesGraphContainer}>
          <strong className={style.cardTemplateLabel}>
            Ventas de {new Date().toLocaleString("es-ES", { month: "long" })}{" "}
          </strong>
          <div className={style.firstRowContainer}>
            <CardTemplate flex={3}>
              {screenSize?.width > MAX_WIDTH_MOBILE ? (
                <MonthGraph
                  arrayData={salesState.monthlyData.sales.monthlySalesChart}
                />
              ) : (
                <MonthSalesCalendar
                  salesData={salesState.monthlyData.sales.monthlySalesChart}
                />
              )}
            </CardTemplate>
            <div className={style.salesCardsColumnContainer}>
              <CardTemplate containerCustomStyle={{ padding: "14px 20px" }}>
                <CardSimpleSales
                  label={"Ticket promedio"}
                  data={salesState.monthlyData.sales.sales.averageTickets}
                  isMoneyFormat
                />
              </CardTemplate>
              <CardTemplate containerCustomStyle={{ padding: "14px 20px" }}>
                <CardSimpleSales
                  label={"Reservas"}
                  data={salesState.monthlyData.sales.sales.appointments}
                />
              </CardTemplate>
              <CardTemplate containerCustomStyle={{ padding: "14px 20px" }}>
                <CardSimpleSales
                  label={"Clientes atendidos"}
                  data={salesState.monthlyData.sales.sales.clientsAttended}
                />
              </CardTemplate>
            </div>
          </div>
        </div>
        <div className={style.purchasesSalesCardsContainer}>
          <CardHorBarGraph
            title="Paquetes vendidos"
            data={salesState.monthlyData.sales.purchases.packages}
            setModalData={setModalContent}
          />
          <CardHorBarGraph
            title="Servicios más vendidos"
            data={salesState.monthlyData.sales.purchases.services}
            setModalData={setModalContent}
          />
          <CardHorBarGraph
            title="Productos más vendidos"
            data={salesState.monthlyData.sales.purchases.products}
            setModalData={setModalContent}
          />
          {/* <CardTemplate title="Giftcards vendidas" flex={1}>
            <CardGiftcards
              data={salesState.monthlyData.sales.purchases.giftcards}
            />
          </CardTemplate> */}
        </div>
      </section>
      <ServicesModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        data={modalData}
        title={modalTitle}
      />
    </>
  );
};

export default MonthModule;
