import React from "react";
// @ts-ignore
import style from "./SectionTitle.module.css";
import { getCurrentDate } from "@Utilities/dateFunctions";

const SectionTitle = ({ idPeriod }) => {
  const PERIOD = [
    { id: 1, name: "día" },
    { id: 2, name: "semana" },
    { id: 3, name: "mes" },
  ];

  return (
    <h3 className={style.headerContainer}>
      {idPeriod !== null && (
        <span className={style.periodTitle}>
          Como va tu {PERIOD.find((item) => item.id === idPeriod).name}
        </span>
      )}
      {idPeriod === 1 ? (
        <>
          <span className={style.todayDateTitle}>|</span>
          <span className={style.todayDateTitle}>{getCurrentDate()}</span>
        </>
      ) : null}
    </h3>
  );
};

export default SectionTitle;
