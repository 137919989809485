import { useEffect, useState } from "react";
import style from "./ProgressCircle.module.css";

const ProgressCircle = ({
  percentageValue = 0,
}: {
  percentageValue: number;
}) => {
  if (percentageValue > 99) percentageValue = 99;
  if (percentageValue < 0) percentageValue = 0;

  const [currentLoad, setCurrentLoad] = useState(0);

  useEffect(() => {
    if (currentLoad < percentageValue) {
      setTimeout(() => {
        setCurrentLoad(currentLoad + 1);
      }, 2);
    }
  }, [percentageValue, currentLoad]);

  return (
    <div
      className={style.progressCircle}
      style={{
        background: `conic-gradient(#b554cc ${
          currentLoad * 3.6 || 0
        }deg, #937cf473 ${currentLoad * 3.6 || 0}deg, #937cf473 360deg)`,
      }}
    >
      <div className={style.circleBackground}>
        {Number(percentageValue.toFixed(2))}%
      </div>
    </div>
  );
};

export default ProgressCircle;
