// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZOZcxA83quxMQvnaodRm {
  width: 100%;
  aspect-ratio: 1 / 1;
  transform: rotate(-120deg);
  border-radius: 50%;
  display: relative;
  display: grid;
  place-items: center;
}

.eD9GIkzwOC999vWe7HmZ {
  width: 80%;
  height: 80%;
  background-color: #fff;
  transform: rotate(120deg);
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-weight: 700;
  color: #45348e;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/ProgressCircle/ProgressCircle.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,0BAA0B;EAC1B,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".progressCircle {\n  width: 100%;\n  aspect-ratio: 1 / 1;\n  transform: rotate(-120deg);\n  border-radius: 50%;\n  display: relative;\n  display: grid;\n  place-items: center;\n}\n\n.circleBackground {\n  width: 80%;\n  height: 80%;\n  background-color: #fff;\n  transform: rotate(120deg);\n  border-radius: 50%;\n  display: grid;\n  place-items: center;\n  font-weight: 700;\n  color: #45348e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressCircle": `ZOZcxA83quxMQvnaodRm`,
	"circleBackground": `eD9GIkzwOC999vWe7HmZ`
};
export default ___CSS_LOADER_EXPORT___;
