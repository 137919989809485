// Models
import { STATUS_INTERNAL_SERVER_ERROR, STATUS_OK } from "@Models/httpsStatus";
import { IMonthlyData } from "@Models/interfaces/sales";

// Services
import { getMonthlySales_service } from "@Services/getSales/getMonthlySales";

// Redux
import { AppDispatch } from "@ReduxService/index";
import { updateCustomPeriodData } from "@ReduxService/states/salesSlice";

/**
 * Adaptador para obtener datos de ventas en un período personalizado
 * @param dispatch - Dispatch de Redux para actualizar el estado
 * @param startDate - Fecha de inicio en formato YYYY-MM-DD
 * @param endDate - Fecha de fin en formato YYYY-MM-DD
 */
export const getCustomPeriodSales = async (
  dispatch: AppDispatch,
  startDate: string,
  endDate: string
) => {
  try {
    // Obtener datos de ventas para el período especificado
    const customPeriodResponse = await getMonthlySales_service(
      startDate,
      endDate
    );

    // Adaptar los datos al formato requerido
    const customPeriodData: IMonthlyData = {
      sales: {
        totalSales: {
          sales:
            customPeriodResponse.data.monthlySalesData.currentMonthSales
              .monthlySales || 0,
          salesQuantity:
            customPeriodResponse.data.monthlySalesData.currentMonthSales
              .totalProductQuantity || 0,
        },
        yestardaySales: {
          sales: 0,
          salesQuantity: 0,
        },
        monthlySalesChart: customPeriodResponse.data.monthlySalesChart,
        purchases: {
          giftcards: {
            currentMonth: {
              total:
                customPeriodResponse.data.giftcardData.currentMonthGiftcards[0]
                  ?.quantity || 0,
              totalAmount:
                customPeriodResponse.data.giftcardData.currentMonthGiftcards[0]
                  ?.total || 0,
            },
            previousMonth: {
              total:
                customPeriodResponse.data.giftcardData.previousMonthGiftcards[0]
                  ?.quantity || 0,
              totalAmount:
                customPeriodResponse.data.giftcardData.previousMonthGiftcards[0]
                  ?.total || 0,
            },
          },
          products: customPeriodResponse.data.monthlyServiceData.products,
          packages: customPeriodResponse.data.monthlyServiceData.packages,
          services: customPeriodResponse.data.monthlyServiceData.services,
        },
        sales: {
          averageTickets: {
            currentMonth:
              customPeriodResponse.data.monthlySalesData.currentMonthSales
                .averageTicket,
            previousMonth:
              customPeriodResponse.data.monthlySalesData.previousMonthSales
                .averageTicket,
          },
          totalSales: {
            currentMonth:
              customPeriodResponse.data.monthlySalesData.currentMonthSales
                .monthlySales,
            previousMonth:
              customPeriodResponse.data.monthlySalesData.previousMonthSales
                .monthlySales,
          },
          clientsAttended: {
            currentMonth:
              customPeriodResponse.data.monthlySalesData.currentMonthSales
                .customersServed,
            previousMonth:
              customPeriodResponse.data.monthlySalesData.previousMonthSales
                .customersServed,
          },
          appointments: {
            currentMonth:
              customPeriodResponse.data.monthlySalesData.currentMonthSales
                .reservationCount,
            previousMonth:
              customPeriodResponse.data.monthlySalesData.previousMonthSales
                .reservationCount,
          },
        },
      },
      goal: {
        idGoal: customPeriodResponse.data.goalsData.id,
        value: customPeriodResponse.data.goalsData.monthlyGoal,
      },
    };

    // Actualizar el estado con los datos del período personalizado
    dispatch(updateCustomPeriodData(customPeriodData));

    return {
      status:
        customPeriodResponse.status === STATUS_OK
          ? STATUS_OK
          : STATUS_INTERNAL_SERVER_ERROR,
      message: "Operación realizada con éxito",
    };
  } catch (error) {
    return {
      status: STATUS_INTERNAL_SERVER_ERROR,
      message:
        "Error al obtener las ventas del período personalizado,\n intente de nuevo más tarde",
    };
  }
};
