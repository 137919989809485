import { baseUrl, config, responseHandler } from "@Services/constGlobals";
import axios from "axios";

export const getWeeklySales_service = async (
  startDate: string,
  endDate: string
) => {
  const url = `${baseUrl}/SalesReport/weeklyData?fechaDesde=${startDate}&fechaHasta=${endDate}`;
  const service = await axios.get(url, config);
  const response = responseHandler(() => service);
  return response;
};
