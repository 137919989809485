import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

// Styles
// @ts-ignore
import style from "./CardHorBarGraph.module.css";

// Utils
import { formatToSoles } from "@Utilities/texts";

// Components
// @ts-ignore
import { Icon } from "@viuti/recursos";
import { OpenBoxIcon } from "@Models/icons";
import BooleanButtons from "../BooleanButtons/BooleanButtons";
import { QUANTITY_MEASUREMENT } from "@Models/const/measurements";

const CardHorBarGraph = ({ title, data, setModalData }) => {
  let dataOrdenada = [];
  let total = [];

  if (data.length > 0) {
    dataOrdenada = [...data].sort((a, b) => b.quantity - a.quantity);
    total = data.reduce((acc, item) => acc + item.quantity, 0);
  }

  return (
    <div className={style.parentContainer}>
      <div className={style.cardTitle}>{title}</div>
      <div className={style.cardContainer}>
        {dataOrdenada.length > 0 ? (
          <ContentWithData
            title={title}
            data={dataOrdenada}
            total={total}
            setModalData={setModalData}
          />
        ) : (
          <ContentWithoutData title={title} />
        )}
      </div>
    </div>
  );
};

const ContentWithData = ({ title, data, total, setModalData }) => {
  const cardContent = useRef(null);

  const [salesCardMeasurement, setSalesCardMeasurement] =
    useState(QUANTITY_MEASUREMENT);

  const [contentWidth, setContentWidth] = useState(0);

  // Limitar el nombre de los servicios al ancho del contenedor
  const limitName = (nombre, anchoLimite) => {
    const promedioCaracterPx = 7;
    if (nombre.length * promedioCaracterPx > anchoLimite) {
      return (
        nombre.slice(0, Math.floor(anchoLimite / promedioCaracterPx) - 3) +
        "..."
      );
    } else {
      return nombre;
    }
  };

  // Capitalizar la primera letra del título y convertirlo a minúsculas
  const formatTitle = (title, value) => {
    let titleCapitalized =
      title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
    titleCapitalized += ` (${value})`;
    return titleCapitalized;
  };

  const formatItemName = (item) => {
    const name = item.name || "Sin nombre";
    return formatTitle(
      name?.charAt(0).toUpperCase() + name?.slice(1),
      salesCardMeasurement === QUANTITY_MEASUREMENT
        ? item.quantity
        : formatToSoles(item.price)
    );
  };

  useLayoutEffect(() => {
    setContentWidth(cardContent.current.offsetWidth);
  }, []);

  useEffect(() => {
    let current = cardContent.current;

    window.addEventListener("resize", () => {
      setContentWidth(current.offsetWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setContentWidth(current.offsetWidth);
      });
    };
  }, []);

  return (
    <>
      <div className={style.cardWithDataContent} ref={cardContent}>
        <BooleanButtons
          value={salesCardMeasurement}
          onChange={setSalesCardMeasurement}
        />
        {data.map((item, index) => {
          if (index < 3) {
            const uniqueKey = item.id || index;
            return (
              <div className={style.itemContainer} key={uniqueKey}>
                <div className={style.itemTitleContainer}>
                  <p
                    style={{ margin: 0, fontSize: 12 }}
                    title={formatItemName(item)}
                  >
                    {limitName(formatItemName(item), contentWidth)}
                  </p>
                </div>
                <div className={style.itemBarContainer}>
                  <div
                    style={{
                      width: `${(item.quantity / total) * 100}%`,
                      height: "100%",
                      backgroundColor: "#937CF4",
                      transition: "all 100ms ease-in-out",
                    }}
                  />
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className={style.openModalButtonContainer}>
        <button
          className={style.openModalButton}
          onClick={() => setModalData(data, title)}
        >
          Ver todo
        </button>
      </div>
    </>
  );
};

const ContentWithoutData = ({ title }) => {
  return (
    <div className={style.cardWithoutDataContainer}>
      <span className={style.boxComponent}>
        <Icon path={OpenBoxIcon} color={"#45348E"} size={48}></Icon>
      </span>
      <div className={style.cardWithoutDataContent}>
        <p className={style.emptyBoxText}>
          No hay {title.toLowerCase().split(" ")[0]}
        </p>
        <p className={style.subEmptyBoxText}>
          ¡Agrega {title.toLowerCase().split(" ")[0]} para ver estadísticas!
        </p>
      </div>
    </div>
  );
};

export default CardHorBarGraph;
