import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import {
  IDailyData,
  IMonthlyData,
  IWeeklyData,
} from "@Models/interfaces/sales";
import { MOCK_DAILY_DATA } from "../../_mocks_/dailySales.mock";
import { MOCK_WEEKLY_DATA } from "../../_mocks_/weeklySales.mock";
import { MOCK_MONTHLY_DATA } from "../../_mocks_/monthlySales.mock";

export interface ISalesState {
  dailyData: IDailyData;
  weeklyData: IWeeklyData;
  monthlyData: IMonthlyData;
  customPeriodData: IMonthlyData;
}

const DEFAULT_STATE = {
  dailyData: MOCK_DAILY_DATA,
  monthlyData: MOCK_MONTHLY_DATA,
  weeklyData: MOCK_WEEKLY_DATA,
  customPeriodData: MOCK_MONTHLY_DATA,
};

const initialState: ISalesState = (() => {
  return DEFAULT_STATE;
})();

export const salesSlice = createSlice({
  name: "salesSlice",
  initialState,
  reducers: {
    updateDailySalesData: (
      state,
      action: PayloadAction<ISalesState["dailyData"]>
    ) => {
      state.dailyData = action.payload;
    },
    updateMonthlySalesData: (
      state,
      action: PayloadAction<ISalesState["monthlyData"]>
    ) => {
      state.monthlyData = action.payload;
    },
    updateWeeklySalesData: (
      state,
      action: PayloadAction<ISalesState["weeklyData"]>
    ) => {
      state.weeklyData = action.payload;
    },
    updateWeekGoal: (
      state,
      action: PayloadAction<{
        idGoal: number;
        value: number;
      }>
    ) => {
      state.weeklyData.goal = action.payload;
    },
    updateCustomPeriodData: (
      state,
      action: PayloadAction<ISalesState["customPeriodData"]>
    ) => {
      state.customPeriodData = action.payload;
    },
  },
});

export default salesSlice.reducer;
export const {
  updateDailySalesData,
  updateMonthlySalesData,
  updateWeeklySalesData,
  updateWeekGoal,
  updateCustomPeriodData,
} = salesSlice.actions;
